import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import SpecialEventPage from '../components/page/SpecialEventPage/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanitySpecialEvent(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      teaserImage {
        asset {
          url
          fluid(maxWidth: 250) {
            ...GatsbySanityImageFluid
          }
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      mainImageSpecialEvents {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
// console.log({data: {sanityPage: page}})

const PagePage = ({data: {sanitySpecialEvent: page, sanitySiteSettings: site}}) => (

  <Layout mainImage={page.mainImage && page.mainImage.asset ? page.mainImage : site.mainImageSpecialEvents}title={page.title}>
    {page.seoSettings && page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <SpecialEventPage
      title={page.title}
      _rawBody={page._rawBody}
      mainImage={page.mainImage}
      teaserImage={page.teaserImage}
      // sideBar={page.pageSidebar}
    />
  </Layout>
)

export default PagePage
