import React from 'react'
import PortableText from '../../serializers/portableText'
// import styles from './specialevent.module.css'
export default function SpecialEvent ({_rawBody, teaserImage}) {
  return (
    <>
      {_rawBody && <PortableText blocks={_rawBody} />}
    </>
  )
}
